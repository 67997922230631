import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Gallery from "./Gallery";
import Achievements from "./Achievements";
import Events from "./Events";
import Tutorials from "./Tutorials";
import Team from "./Team";

const Pages = () => (
  <Switch>
    <Route path="/" component={Home} exact={true} />
    <Route path="/about" component={About} />
    <Route path="/gallery" component={Gallery} />
    <Route path="/achievements" component={Achievements} />
    <Route path="/events" component={Events} />
    <Route path="/tutorials" component={Tutorials} />
    <Route path="/team" component={Team} />
  </Switch>
);

export default Pages;
