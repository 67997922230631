import React from "react";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Pages from "./pages";

function App() {
  return (
    <div className="App">
      <Header />
      <Pages />
      <Footer />
    </div>
  );
}

export default App;
