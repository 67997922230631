import React from "react";

const About = () => (
  <div>
    <h1>About Us</h1>
    <p>
      Microcontroller Based Systems and Robotics Hub (μCR), as the name suggests
      is the robotics Hub of Jaypee Institute of Information Technology. We are
      a team of skilled students from all years who prove their worth, not just
      by talking, but by winning all sorts of Robotics competitions held in many
      colleges throughout the country, including IITs, DCE and NSIT.
    </p>
    <p>
      Apart from winning, we have been hosting all sorts of Robotics events
      since the formation of the hub. Every year we organize our annual Robotics
      events during the college Tech-fest, which are among the most well known
      Robotics events in the National Capital Region. We are based on a very
      simple concept of learning by teaching: all senior students who specialize
      in their respective fields take classes for first and second years on a
      regular basis, who in turn will teach their juniors in the future. We are
      well versed in Manual as well as Autonomous Robotics, and everyday we put
      a step forward to improve our technical knowledge in these, as well as
      other fields.
    </p>

    <h5>FACULTY ADVISOR: Hema N, CSE Dept.</h5>
  </div>
);

export default About;
