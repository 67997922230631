import React from "react";

const Footer = () => (
  <div>
    Copyright© μCR. All rights reserved.
    <br />
    Designed & Developed by @itsharsh @ayush @saloni
    <br />
    Follow us on FB, GH, IG
  </div>
);

export default Footer;
