import React from "react";
import { NavLink } from "react-router-dom";

const routes = [
  "About",
  "Gallery",
  "Achievements",
  "Events",
  "Tutorials",
  "Team",
];

const Header = () => (
  <header>
    <nav>
      UCR JIIT
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        {routes.map((route) => (
          <li key={route}>
            <NavLink to={`/${route.toLowerCase()}`}>{route}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  </header>
);

export default Header;
